import Hamburger from './hamburger'
import { NavLink, routes, Link } from '@redwoodjs/router'
import { useAuth } from '@redwoodjs/auth'

const NavBarTest = ({ sidebarOpen, setSidebarOpen }) => {
  const onHamburgerClick = () => {
    setSidebarOpen(!sidebarOpen)
  }

  const { isAuthenticated, currentUser, ...props } = useAuth()
  // console.log({ props })
  const { logOut } = props

  const onLogoutClick = async () => {
    return await logOut()
  }

  return (
    <>
      <nav className="bg-gray-800 min-w-full">
        <div className=" mx-auto px-2 sm:px-6 lg:px-8 ">
          <div className="shadow-lg">
            <div className="relative flex items-center justify-between h-16 ">
              {/* This is the hidden menu button that becomes visible below the small screen size*/}
              <div className="absolute inset-y-o left-0 flex items-center sm:hidden">
                <Hamburger
                  open={sidebarOpen}
                  onHamburgerClick={onHamburgerClick}
                />
              </div>

              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                {/* This is the logo on the left side of the menu bar */}
                <div className="flex-shrink-0 flex items-center ">
                  <img
                    className="block lg:hidden h-12 w-auto"
                    src="/dish and fork logo.png"
                    alt="OurFamilyCookbook"
                  />
                  <img
                    className="hidden lg:block h-12 w-auto"
                    src="/dish and fork logo text.png"
                    alt="OurFamilyCookbook"
                  />
                </div>
                {/* These are the menu items that will move to the drawer on small screens */}
                <div className="hidden  sm:block sm:ml-10">
                  <div className="flex space-x-4">
                    <NavLink
                      className="px-3 py-2 text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                      activeClassName="px-3 py-2 bg-yellow-700 text-white rounded-md"
                      to={routes.home()}
                    >
                      Home
                    </NavLink>
                    <NavLink
                      className="px-3 py-2 text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                      activeClassName="px-3 py-2 bg-yellow-700 text-white rounded-md"
                      to={routes.about()}
                    >
                      About Us
                    </NavLink>
                    {/* <NavLink
                      className="px-3 py-2 text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                      activeClassName="px-3 py-2 bg-yellow-700 text-white rounded-md"
                      to={routes.features()}
                    >
                      Features
                    </NavLink> */}

                    {!isAuthenticated ? (
                      <>
                        <NavLink
                          className="px-3 py-2 text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                          activeClassName="px-3 py-2 bg-yellow-700 text-white rounded-md"
                          to={routes.createCookbook()}
                        >
                          Create Cookbook
                        </NavLink>
                        <NavLink
                          className="px-3 py-2 text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                          activeClassName="px-3 py-2 bg-yellow-700 text-white rounded-md"
                          to={routes.login()}
                        >
                          Login
                        </NavLink>
                      </>
                    ) : (
                      <>
                        <NavLink
                          className="px-3 py-2 text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                          activeClassName="px-3 py-2 bg-yellow-700 text-white rounded-md"
                          to={routes.dashboard({
                            cookbookId: currentUser.user_metadata.lastCookbook,
                          })}
                        >
                          My Cookbooks
                        </NavLink>
                        <button
                          className="px-3 py-2 text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                          activeclassname="px-3 py-2 bg-yellow-700 text-white rounded-md"
                          onClick={onLogoutClick}
                        >
                          Logout
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Menu here */}
      </nav>
      {/* {children} */}
    </>
  )
}

export default NavBarTest
