const FeatureTile = ({ title, text, children }) => {
  return (
    <div className=" border-solid border-1 border-gray-900 rounded-lg hover:shadow-lg p-2 ">
      <div className="flex mb-2">
        {children}
        <div className="font-bold text-xl p-2 text-black">{title}</div>
      </div>
      <div className="text-sm">{text}</div>
    </div>
  )
}

export default FeatureTile
