import Footer from 'src/components/Footer/Footer'
import NavBarTest from 'src/components/NavBarTest/NavBarTest'
import { useState } from 'react'
import Sidebar from 'src/components/Sidebar/Sidebar'

const HomeLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  return (
    <div id="__next" className="relative ">
      <div className="fixed top-0 z-40 lg:z-50 w-full mx-auto flex-none flex">
        <NavBarTest sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      </div>
      <div className="relative top-16 overflow-y-scroll ">
        <div className="flex flex-row ">
          <Sidebar open={sidebarOpen} setOpen={sidebarOpen} />
          <div id="content wrapper" className="flex flex-auto min-h-screen">
            {children}
          </div>
        </div>
        <div className="absolute min-w-full">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default HomeLayout
