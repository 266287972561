export const camelize = (str) => {
  return str
    .split(' ')
    .map((value, index) => {
      return index === 0
        ? value.toLowerCase()
        : value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()
    })
    .join('')
}

export const sentenceCase = (str) => {
  return str
    .split(' ')
    .map((word, index) => {
      return index === 0
        ? word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
        : word.toLowerCase()
    })
    .join('')
}

const MAX_STRING_LENGTH = 150

export const truncate = (
  text,
  fullWord = false,
  length = MAX_STRING_LENGTH
) => {
  let output = text
  const origLength = length
  let charAtEnd = text.length > length ? text.substring(length, length) : ' '
  if (fullWord && charAtEnd !== ' ') {
    while (charAtEnd !== ' ' && length < text.length) {
      charAtEnd = text.substring(length++, length)
    }
    length = length == text.length ? origLength : length - 1
  }
  if (text && text.length > length) {
    output = output.substring(0, length) + '...'
  }
  return output
}
