export const CREATE_COOKBOOK_USER = gql`
  mutation CreateCookbookUser($input: CreateCookbookUserInput!) {
    createCookbookUser(input: $input) {
      id
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      lastCookbook
      updatedAt
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      firstName
      lastName
    }
  }
`

export const CREATE_COOKBOOK = gql`
  mutation CreateCookbook($input: CreateCookbookInput!) {
    createCookbook(input: $input) {
      id
      name
      createdBy
    }
  }
`

export const FIND_USER_BY_EMAIL = gql`
  mutation findUser($email: String!) {
    findUser(email: $email) {
      id
      email
    }
  }
`

export const DELETE_INVITATION = gql`
  mutation DeleteInvitation($id: String!) {
    deleteInvitation(id: $id) {
      id
    }
  }
`
export const FIND_COOKBOOK_USER = gql`
  mutation findCookbookUser($userId: String!, $cookbookId: String!) {
    findCookbookUser(userId: $userId, cookbookId: $cookbookId) {
      id
      role
      admin
    }
  }
`
