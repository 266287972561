import { NavLink, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { useCookbookContext } from 'src/context/cookbookContext'
export const QUERY = gql`
  query FindCookbooks($userId: String!) {
    findCookbooks(userId: $userId) {
      cookbook {
        id
        name
      }
    }
  }
`
const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      lastCookbook
      updatedAt
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => <div>Error: {error.message}</div>

export const Success = ({ findCookbooks, userId }) => {
  const { cookbookId, setCookbookId } = useCookbookContext()
  const [updateUser] = useMutation(UPDATE_USER, {})
  const onClick = async (lastCookbook) => {
    console.log('DID THIS GET CALLED????', { lastCookbook })
    const res = await updateUser({
      variables: { input: { id: userId, lastCookbook } },
    })
    console.log(res)
    setCookbookId(lastCookbook)
    console.log({ lastCookbook, cookbookId, setCookbookId })
  }
  return findCookbooks.map((cookbook, index) => (
    <div key={index} className="m-2">
      <NavLink
        to={routes.dashboard({ cookbookId: cookbook.cookbook.id })}
        className="block px-3 py-2 text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
        activeClassName="px-3 py-2 bg-yellow-700 text-white rounded-md"
      >
        <button onClick={() => onClick(cookbook.cookbook.id)}>
          {cookbook.cookbook.name}
        </button>
      </NavLink>
    </div>
  ))
}
