import { useEffect } from 'react'
import { useCookbookContext } from 'src/context/cookbookContext'
import { sentenceCase } from 'src/utils/stringUtils'

export const QUERY = gql`
  query FindCookbookUsers($cookbookId: String!) {
    findCookbookUsers(cookbookId: $cookbookId) {
      user {
        id
        firstName
        lastName
      }
      role
      admin
    }
  }
`

export const Loading = () => <div className="w-full">Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => <div>Error: {error.message}</div>

export const Success = ({ findCookbookUsers, refetch }) => {
  const { userAdded, setUserAdded } = useCookbookContext()
  useEffect(() => {
    if (userAdded) {
      setUserAdded(false)
      refetch()
    }
  }, [userAdded, setUserAdded, refetch])

  return findCookbookUsers.map((user, index) => (
    <div key={index} className="flex flex-row px-2 mx-2 align-middle">
      {
        <div
          className="text-yellow-700 self-center"
          style={{ color: user.admin ? 'rgba(180, 83, 9)' : 'transparent' }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check-circle"
            className="svg-inline--fa fa-check-circle fa-w-16"
            role="img"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
            ></path>
          </svg>
        </div>
      }
      <div className="m-2 text-gray-300 text-sm">{`${user.user.firstName} ${
        user.user.lastName
      } - ${sentenceCase(user.role)}`}</div>
    </div>
  ))
}
