import { useAuth } from '@redwoodjs/auth'
import SidePanelItem from 'src/components/SidePanelItem'
import CookbookuserCell from 'src/components/CookbookuserCell'
import UsersInCookbookCell from 'src/components/UsersInCookbookCell'
import { useCookbookContext } from 'src/context/cookbookContext'
import { routes } from '@redwoodjs/router'

const SecureLayout = (props) => {
  const { logOut } = useAuth()
  const { children } = props
  const { cookbookId, userId, resetCookbookData } = useCookbookContext()
  const onLogoutClick = async () => {
    resetCookbookData()
    return await logOut()
  }

  return (
    <div className="flex min-w-full min-h-screen top-16">
      {/* This is the sidebar element when on desktop
        TODO: Push to side bar when on mobile */}
      <div className="max-w-max w-56 bg-gray-800">
        <div className="flex flex-col h-screen">
          {/* CookBooks */}
          <SidePanelItem title="Cookbooks" page={routes.createCookbook()}>
            <CookbookuserCell userId={userId} cookbookId={cookbookId} />
          </SidePanelItem>
          {/* Users Menu Item */}
          <SidePanelItem
            title="Users"
            page={routes.addUser({ cookbookId: cookbookId })}
          >
            <UsersInCookbookCell cookbookId={cookbookId} />
          </SidePanelItem>
          {/* Account Area */}
          <SidePanelItem title="Account" addItem={false}>
            <div className="flex flex-row px-2 mx-2 align-middle">
              <button
                onClick={onLogoutClick}
                className="text-gray-300 self-center text-sm"
              >
                Logout
              </button>
            </div>
          </SidePanelItem>
        </div>
      </div>
      {children}
    </div>
  )
}

export default SecureLayout
