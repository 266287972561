import { useAuth } from '@redwoodjs/auth'
import { useContext, createContext, useState, useEffect } from 'react'
import { useQuery } from '@redwoodjs/web'
import { useLazyQuery, useMutation } from '@apollo/client'

import { FIND_COOKBOOK_USER } from 'src/graphql/mutations'

const COOKBOOK_NAME = gql`
  query Cookbook($id: String!) {
    cookbook(id: $id) {
      id
      name
    }
  }
`

const USER = gql`
  query User($id: String!) {
    user(id: $id) {
      firstName
      lastName
    }
  }
`

const CookbookContext = createContext()

export const useCookbookContext = () => {
  const context = useContext(CookbookContext)
  if (context === undefined) {
    throw new Error('useCookbookContext must be used within a CookbookProvider')
  }
  return context
}

export const CookbookProvider = ({ children }) => {
  const { currentUser } = useAuth()
  // console.log({ currentUser })
  const [cookbookId, setCookbookId] = useState(
    currentUser?.user_metadata.lastCookbook
  )
  const [cookbookName, setCookbookName] = useState('')
  const [userId, setUserId] = useState(currentUser?.sub)
  const [userAdded, setUserAdded] = useState(false)

  const { refetch: refetchCookbookName } = useQuery(COOKBOOK_NAME, {
    variables: { id: currentUser?.user_metadata.lastCookbook },
  })

  const [getUser, { loading, data }] = useLazyQuery(USER, {})
  const resetCookbookData = () => {
    setUserId('')
    setCookbookId('')
  }
  const [userName, setUserName] = useState('')

  const [userRoles, setUserRoles] = useState([])
  const [findCookbookUser] = useMutation(FIND_COOKBOOK_USER)

  useEffect(() => {
    const fetchData = async () => {
      const cookbookData = await refetchCookbookName({ id: cookbookId })
      setCookbookName(cookbookData.data.cookbook.name)
    }
    fetchData()
  }, [cookbookId, refetchCookbookName, cookbookName])

  useEffect(() => {
    if (currentUser) {
      getUser({ variables: { id: currentUser.sub } })
      setUserName(`${data?.user.firstName} ${data?.user.lastName}`)
    }
  }, [currentUser, getUser, data])

  useEffect(() => {
    if (currentUser) {
      const findUser = async () => {
        const { data } = await findCookbookUser({
          variables: { userId: currentUser.sub, cookbookId },
        })

        const { findCookbookUser: userData } = data
        updateRoles({ ...userData })
      }
      findUser()
    }
  }, [cookbookId, findCookbookUser, currentUser])

  const updateRoles = ({ admin, role }) => {
    let userRoles = [role, admin ? 'ADMIN' : null]
    setUserRoles(userRoles)
  }

  const hasRole = (role) => {
    return userRoles.includes(role)
  }

  return (
    <CookbookContext.Provider
      value={{
        cookbookId,
        setCookbookId,
        userId,
        setUserId,
        resetCookbookData,
        userAdded,
        setUserAdded,
        cookbookName,
        userName,
        hasRole,
      }}
    >
      {children}
    </CookbookContext.Provider>
  )
}
