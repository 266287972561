import { Link, routes } from '@redwoodjs/router'
import { Form, Label, Submit, TextAreaField, TextField } from '@redwoodjs/forms'
import styles from 'src/utils/styles'

const Footer = () => {
  return (
    <div className="bg-gray-900">
      <div className="max-w-screen-7xl">
        <div className="grid grid-rows-3 sm:grid-rows-1 sm:grid-cols-3 grid-flow-col sm:grid-flow-row px-10 mx-auto text-gray-300 sm:justify-items-center space-y-4 sm:spce-y-0">
          <div className="w-48 m-5">
            <div className="text-xl mt-5">Contact Us</div>
            <Form className="flex flex-col">
              <div className="flex flex-col mb-4">
                <Label
                  name="name-footer"
                  className="label"
                  errorClassName="label error"
                >
                  Full Name
                </Label>
                <TextField
                  name="name-footer"
                  placeholder="Julia Child"
                  className="input h-5 tet-sm  text-gray-800 px-2 py-4"
                  errorClassName="input error"
                />
              </div>
              <div className="flex flex-col mb-4">
                <Label
                  name="email-footer"
                  className="label"
                  errorClassName="label error"
                >
                  Email
                </Label>
                <TextField
                  name="email-footer"
                  placeholder="something@anything.com"
                  className="input text-sm h-5 text-gray-800 px-2 py-4"
                  errorClassName="input error"
                  validation={{
                    required: true,
                    pattern: {
                      value: /[^@]+@[^\.]+\..+/,
                    },
                  }}
                />
              </div>
              <div className="flex flex-col mb-4">
                <Label
                  name="message-footer"
                  className="label"
                  errorClassName="label error"
                >
                  Message
                </Label>
                <TextAreaField
                  name="message-footer"
                  className="text-sm  text-gray-800 p-2"
                  placeholder="Lorem ipsum dolor sit amet, consectetur..."
                />
              </div>
              <Submit className={styles.button}>Submit</Submit>
            </Form>
          </div>

          <div className="flex flex-col w-48 pl-5">
            <div className="text-xl mt-5">Site Map</div>
            <div className="flex flex-col text-md">
              <Link to={routes.home()}>Home</Link>
              <Link to={routes.features()}>Features</Link>
              <Link to={routes.about()}>About Us</Link>
            </div>
          </div>
          <div className="w-48 ">
            <div className="flex flex-col">
              <img
                src="/dish and fork logo text.png"
                className="w-48 p2 my-5 "
              />
              <div className="flex flex-row m-2 items-center">
                <div className="p-2">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="envelope"
                    className="svg-inline--fa fa-envelope"
                    role="img"
                    width="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"
                    ></path>
                  </svg>
                </div>
                <a href="mailto:ourfamilycookbookapp@gmail.com" className="">
                  Email Us!
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="h-10 text-center bg-black text-gray-300">
          Copyright 2021 All Rights Reserved Our Eatz
        </div>
      </div>
    </div>
  )
}

export default Footer
