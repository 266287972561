// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Private, Set } from '@redwoodjs/router'
import HomePage from 'src/pages/HomePage'
import { CookbookProvider, useCookbookContext } from './context/cookbookContext'
import HomeLayout from './layouts/HomeLayout/HomeLayout'
import SecureLayout from './layouts/SecureLayout/SecureLayout'

const Routes = () => {
  return (
    <Router>
      <Private unauthenticated="home">
        <Set wrap={[CookbookProvider, SecureLayout]}>
          <Route path="/{cookbookId}/dashboard" page={DashboardPage} name="dashboard" />
          <Route path="/{cookbookId}/new-cookbook" page={NewCookbookPage} name="newCookbook" />
          <Route path="/{cookbookId}/create-recipe" page={CreateRecipePage} name="createRecipe" />
          <Route path="/{cookbookId}/add-user" page={AddUserPage} name="addUser" />
          <Route path="/{cookbookId}/recipe/{recipeId}" page={RecipePage} name="recipe" />
        </Set>
      </Private>
      <Set wrap={[HomeLayout]}>
        <Route path="/invitation/{invitationId}" page={InvitationPage} name="invitation" />
        <Route path="/features" page={FeaturesPage} name="features" />
        <Route path="/about" page={AboutPage} name="about" />
        <Route path="/create-cookbook" page={CreateCookbookPage} name="createCookbook" />
        <Route path="/login" page={LoginPage} name="login" />
        <Route path="/" page={HomePage} name="home" prerender />
        <Route notfound page={NotFoundPage} />
      </Set>
    </Router>
  )
}

export default Routes
