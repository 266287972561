import { NavLink, routes } from '@redwoodjs/router'

const Sidebar = ({ open, setOpen }) => {
  return (
    <div
      className="block bg-gray-900"
      style={{
        display: open ? 'block' : 'none',
      }}
    >
      <div className="px-2 pt-2 pb-3 space-y-1">
        {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
        <NavLink
          to={routes.home()}
          className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          activeClassName="bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
          onClick={setOpen}
        >
          Home
        </NavLink>
        <NavLink
          to={routes.about()}
          className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          activeClassName="bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
          onClick={setOpen}
        >
          About Us
        </NavLink>
        <NavLink
          to={routes.createCookbook()}
          className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          activeClassName="bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
          onClick={setOpen}
        >
          Create Cookbook
        </NavLink>
        <NavLink
          to={routes.login()}
          className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          activeClassName="bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
          onClick={setOpen}
        >
          Login
        </NavLink>
      </div>
    </div>
  )
}

export default Sidebar
