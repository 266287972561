import { Link, routes } from '@redwoodjs/router'
import FeatureTile from 'src/components/FeatureTile/FeatureTile'
import styles from 'src/utils/styles'

const HomePage = () => {
  return (
    <div className="flex flex-col flex-auto">
      <div
        className="flex flex-col bg-no-repeat bg-left bg-cover h-96"
        style={{
          backgroundImage: 'url(/conscious-design-JDL3c2zSI_s-unsplash.jpg)',
        }}
      >
        <div className="relative h-96 w-full">
          <div className="absolute bottom-2 left-2 sm:bottom-5 sm:left-5 text-2xl sm:text-4xl max-w-lg text-center text-gray-100">
            <div className="z-40 bg-gray-200 bg-opacity-0 rounded-lg">
              The secure way to share your family recipes
            </div>
          </div>
        </div>
      </div>

      {/* This is the section where we talk about why we made Our Eatz */}
      <div className="relative flex flex-col sm:flex-row bg-gray-100 py-10 items-center sm:items-start">
        <img
          src="/mike-gattorna-IxBCafdQItg-unsplash.jpg"
          className="w-48 h-48 ml-2 sm:ml-24 mt-2 sm:mt-24"
        />
        <div className="ml-2 sm:ml-10 mt-2 sm:mt-20 items-center">
          <div className="block text-gray-900 text-4xl  ">
            Why did we make <b>Our Eatz</b>?
          </div>
          <div className="text-gray-700 text-2xl mt-2">
            It's our family's way of staying connected
          </div>
          <div className="max-w-2xl mt-2 mb-6">
            In our family the gathering spot has always been the kitchen; food,
            conversation and love could always be found there. Now that most of
            our kids have grown and moved to their own homes with their own
            families, we want a way to continue to share those experiences
            through a shared experience of food.
          </div>
          <Link to={routes.about()} className={styles.button}>
            Read More
          </Link>
        </div>
      </div>

      <div className="relative bg-white  items-center">
        <div className="flex flex-col md:flex-row my-24 space-y-10 md:space-y-0 md:space-x-4 justify-between mx-24 max-w-full justify-items-center">
          <FeatureTile
            text="Cloud storage ensures that your recipes are only accessible by
            individuals that you allow to have access. Using the User Portal you
            can control who has access and the type of access they have."
            title="Secure"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="user-lock"
              className="svg-inline--fa fa-user-lock w-7  text-yellow-700"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32zm288-32h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 32 0 0 1 64 0z"
              ></path>
            </svg>
          </FeatureTile>

          <FeatureTile
            text="Share the recipes you have however you want to share them.  You even control what others can do.  Want to let one of the kids edit recipes while others can only view, no problem!"
            title="Shareable"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="share-alt"
              className="svg-inline--fa fa-share-alt w-7 text-yellow-700"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M352 320c-22.608 0-43.387 7.819-59.79 20.895l-102.486-64.054a96.551 96.551 0 0 0 0-41.683l102.486-64.054C308.613 184.181 329.392 192 352 192c53.019 0 96-42.981 96-96S405.019 0 352 0s-96 42.981-96 96c0 7.158.79 14.13 2.276 20.841L155.79 180.895C139.387 167.819 118.608 160 96 160c-53.019 0-96 42.981-96 96s42.981 96 96 96c22.608 0 43.387-7.819 59.79-20.895l102.486 64.054A96.301 96.301 0 0 0 256 416c0 53.019 42.981 96 96 96s96-42.981 96-96-42.981-96-96-96z"
              ></path>
            </svg>
          </FeatureTile>
          <FeatureTile
            text="When you create your recipes you can tag them however you want.  Then later you can search by those tags."
            title="Searchable"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="search"
              className="svg-inline--fa fa-search w-7 text-yellow-700"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
              ></path>
            </svg>
          </FeatureTile>
        </div>
      </div>
    </div>
  )
}

export default HomePage
