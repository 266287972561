import { routes } from '@redwoodjs/router'
import { navigate } from '@redwoodjs/router'
import AddItemButton from 'src/components/AddItemButton/AddItemButton'

const SidePanelItem = ({ title, children, page, addItem = true }) => {
  return (
    <div>
      <div className="flex text-xl justify-between p-2 m-2">
        <div className="text-gray-300 font-bold">{title}</div>
        <div className="self-center">
          {addItem && <AddItemButton onClick={() => navigate(page)} />}
        </div>
      </div>
      <div>{children}</div>
    </div>
  )
}

export default SidePanelItem
